import Vue from 'vue'
import App from './App.vue'
import router from './router'


import 'bootstrap/dist/css/bootstrap.css'


import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
Vue.use(VueAwesomeSwiper);

import "@/assets/styles/ruoyi.scss"

Vue.config.productionTip = false

//注册到vue原型上
import axios from "./utils/request"; // axios
Vue.prototype.$API = axios;

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')


// 修改标题的函数
function changeTitle(newTitle) {
  document.title = newTitle; // 修改页面标题
}

// 在应用初始化后，调用修改标题的函数
changeTitle('河北工院大学科技园');