import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: () => import('../views/index.vue')
  },
	{
		path:'/about',
		name:'about',
		component: () => import('../views/about.vue')
	},
	{
		path:'/Parkservices',
		name:'Parkservices',
		component: () => import('../views/Parkservices.vue')
	},
	{
		path:'/printcenter',
		name:'printcenter',
		component: () => import('../views/printcenter.vue')
	},
	{
		path:'/protection',
		name:'protection',
		component: () => import('../views/protection.vue')
	},
	{
		path:'/fusion',
		name:'fusion',
		component: () => import('../views/fusion.vue')
	},
	{
		path:'/entrepreneurship',
		name:'entrepreneurship',
		component: () => import('../views/entrepreneurship.vue')
	},
	{
		path:'/theater',
		name:'theater',
		component: () => import('../views/theater.vue')
	},
	{
		path:'/testingcenter',
		name:'testingcenter',
		component: () => import('../views/testingcenter.vue')
	},
	{
		path:'/NewsLevel3',
		name:'NewsLevel3',
		component: () => import('../views/NewsLevel3.vue')
	},
	{
		path:'/contact',
		name:'contact',
		component: () => import('../views/contact.vue')
	},
	{
		path:'/news',
		name:'news',
		component: () => import('../views/news.vue')
	},
	{
		path:'/newsInfo',
		name:'nenewsInfows',
		component: () => import('../views/newsInfo.vue')
	}
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
