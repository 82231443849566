import axios from 'axios';
// import store from '@/store';
// import {setLocalStorage,getLocalStorage,removeLocalStorage} from '@/utils/storage.js';


axios.defaults.headers["Content-Type"] = "application/json;charset=utf-8"
// 创建请求服务
const service = axios.create({
	baseURL: 'https://www.hbcitsp.com/api/',
	timeout:5000
})
// 创建请求拦截器
service.interceptors.request.use(config=>{
	config.headers['token'] = 'youtuo111';
	config.headers['mer'] = 'youtuo222';
	return config;
	// config.headers['Content-Type'] = 'application/json;charset=utf-8';
},Error=>{
	return Promise.reject(error);
}
);
	
	
// 创建相应拦截器
service.interceptors.response.use(response=>{
	const code = response.code||200;
	if(code==0){
		this.$message({
			type:'error',
			message:'请求失败'
		})
		return Promise.reject('error');
	}
	if(code==2){
		this.$message({
			type:'error',
			message:'登录失效'
		});
		removeLocalStorage('useInfo');
		this.$router.push('/text');
		return Promise.reject('error');
	}
	return response;
},error=>{
	return Promise.reject(error);
});

// 暴露出拦截器
export default service;